import {useEffect, useState} from "react"

const engineKey = process.env.GATSBY_SWIFTYPE_ENGINE_KEY || "fbQonxyo3rvJoCM1T32T"
const baseUrl = "https://search-api.swiftype.com/api/v1/public/engines/search.json"
const resultsPerPage = 10

const initialState = {
  results: { docs: [], elsewhere: [] },
  total: 0,
}


export const useSwiftype = ({
  searchQuery = "",
  onUpdate = () => {},
  onError = () => {},
}) => {
  const [searchResultsData, setSearchResultsData] = useState(initialState)

  useEffect(async () => {
    let ignore = false

    async function fetchSearchResults() {
      if (!searchQuery || searchQuery.length < 2) {
        return initialState
      }

      try {
        const resp = await fetch(baseUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            engine_key: engineKey,
            q: searchQuery,
            spelling: "retry",
            per_page: resultsPerPage,
            fetch_fields: {
              page: ["title", "url"],
            },
            highlight_fields: {
              page: {
                title: { size: 60, fallback: true },
                body: { size: 60, fallback: true },
              },
            },
          }),
        })
        const data = await resp.json()
        const formattedResults = data.records.page.reduce(
          (obj, entry) => {
            if (entry.url.includes("https://docs.fastly.com")) {
              obj.docs = [...obj.docs, entry]
            } else {
              obj.elsewhere = [...obj.elsewhere, entry]
            }

            return obj
          },
          { docs: [], elsewhere: [] }
        )

        return {
          results: formattedResults,
          total: data.record_count,
        }
      } catch (e) {
        onError(e)
        return initialState
      }
    }

    const resultsData = await fetchSearchResults()

    if (!ignore) {
      setSearchResultsData(resultsData)
    }

    return () => {
      ignore = true
    }
  }, [searchQuery])


  useEffect(() => {
    if (searchResultsData.results) {
      onUpdate(searchResultsData)
    }
  }, [searchResultsData])

  return searchResultsData
}
